import { REACT_APP_API_PREFIX } from "config/env";
import { GET } from "constants/methods";

const service = "account";
const users = `${REACT_APP_API_PREFIX}/${service}/users`;

export function getCurrentUser() {
  const url = `${users}/current`;
  return {
    service,
    method: GET,
    url,
  };
}
