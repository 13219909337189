import {
  createStudentCareer,
  deleteStudentCareer,
  getMyStudentQuizzes,
  getStudentCareers,
  incrementPlayCareerIndex,
  setGuidedTourPlaying,
  setOrbiting,
  setPlayCareerIndex,
  setSelectedCareer,
  setSelectedCareerOnGraph,
  setSelectedQuizOnGraph,
} from "actions";
import { SET_CAREER_PREFERENCES } from "constants/app";
import {
  OPEN_SELECTED_CAREER_ON_GRAPH,
  SET_GRAPH_OPEN,
  SET_GUIDED_TOUR_PLAYING,
  SET_SELECTED_CAREER_ON_GRAPH,
  SET_SELECTED_QUIZ_ON_GRAPH,
} from "constants/graph";
import {
  CREATE_STUDENT_CAREER,
  DELETE_STUDENT_CAREER,
} from "constants/studentCareers";
import { GET_CURRENT_USER } from "constants/users";

export function appMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();

    if (action.type === `${GET_CURRENT_USER}_SUCCESS`) {
      store.dispatch(getMyStudentQuizzes());
      store.dispatch(getStudentCareers());
    }

    if (
      action.type === `${CREATE_STUDENT_CAREER}_SUCCESS` ||
      action.type === `${DELETE_STUDENT_CAREER}_SUCCESS`
    ) {
      store.dispatch(getMyStudentQuizzes());
    }

    if (action.type === SET_CAREER_PREFERENCES && state.user) {
      // studentCareer changed and need to be updated on the API

      const exist = state.studentCareers.find(
        (e) => (e.careerID = action.value.id)
      );
      if (exist) {
        store.dispatch(deleteStudentCareer(exist.id));
      } else {
        store.dispatch(
          createStudentCareer({
            careerID: action.value.id,
            favorite: true,
          })
        );
      }
    }

    if (
      action.type === OPEN_SELECTED_CAREER_ON_GRAPH &&
      state.graph.selectedCareerOnGraph
    ) {
      store.dispatch(setSelectedCareer(state.graph.selectedCareerOnGraph));
      store.dispatch(setGuidedTourPlaying(false));
    }

    if (action.type === SET_SELECTED_QUIZ_ON_GRAPH) {
      store.dispatch(setPlayCareerIndex(0));
    }

    if (action.type === SET_GRAPH_OPEN && !action.graphOpen) {
      store.dispatch(setSelectedCareerOnGraph());
      store.dispatch(setSelectedQuizOnGraph());
    }

    if (action.type === SET_SELECTED_CAREER_ON_GRAPH) {
      store.dispatch(setOrbiting(false));
    }

    if (action.type === SET_GUIDED_TOUR_PLAYING) {
      if (action.guidedTourPlaying) {
        store.dispatch(incrementPlayCareerIndex());
      }
    }

    return next(action);
  };
}
