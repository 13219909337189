import { Avatar, Breadcrumbs, fade, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import Tilt from "hooks/Tilt";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Cpfm from "./cpfm.svg";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CareerBubble({
  career,
  color,
  variant = "bubble",
  showMatch = false,
}) {
  const [notForMe, setNotForMe] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [love, setLove] = React.useState(false);
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const careerPreferences = useSelector((state) => state.careerPreferences);
  React.useEffect(() => {
    if (career) {
      const preference = careerPreferences[career.id];

      if (preference && preference.notForMe !== notForMe) {
        setNotForMe(preference.notForMe);
      }

      if (preference && preference?.love !== love) {
        setLove(preference.love);
      }
    }
  }, [careerPreferences, career]);

  function handleCareerClick() {
    dispatch(Actions.setSelectedCareer(career));
  }

  return (
    <div
      className={classes.root}
      onClick={handleCareerClick}
      style={{
        opacity: notForMe ? 0.3 : 1,
        display: variant === "inline" ? "flex" : undefined,
        width: variant === "inline" ? "calc(100% - 32px)" : 80,
        flexDirection: variant === "inline" ? "row" : "column",
        borderRadius: variant === "inline" && hover ? 4 : 0,
        alignItems: variant === "inline" ? "flex-start" : "center",
        background:
          variant === "inline" && hover ? "white" : "rgba(255,255,255,0)",
        boxShadow:
          variant === "inline" && hover
            ? "0px 2px 10px rgba(155,155,155,0.4)"
            : "0px 2px 10px rgba(155,155,155,0)",
        transition: "background .5s, box-shadow .5s",
      }}
    >
      <Tilt style={{ cursor: "pointer", height: 70 }}>
        <Avatar
          style={{
            height: 70,
            width: 70,
            background: color
              ? fade(color, 0.3)
              : career?.categories[0]?.color &&
                fade(career?.categories[0]?.color, 0.3),
            boxShadow: color && `1px 4px 10px ${fade(color, 0.2)}`,
            color: notForMe ? "black" : undefined,
          }}
        >
          {career?.icon ? (
            <img
              src={career.icon}
              style={{
                height: 60,
                color: "white",
                filter: notForMe ? "invert(100%)" : undefined,
              }}
            />
          ) : (
            <img
              src={Cpfm}
              style={{
                height: 60,
                opacity: 0.4,
                color: "white",
                filter: notForMe ? "invert(100%)" : undefined,
              }}
            />
          )}
        </Avatar>
      </Tilt>
      <Typography
        style={{
          textAlign: variant === "inline" ? "left" : "center",
          paddingLeft: variant === "inline" ? 20 : 0,
          lineHeight: 1,
        }}
        variant={variant === "inline" ? "h6" : "body2"}
      >
        {variant === "inline" ? (
          <Breadcrumbs aria-label="breadcrumb" separator="•">
            {career?.categories?.map((cc) => (
              <Typography
                variant="caption"
                style={{ color: cc.color, height: 20 }}
              >
                {cc?.title}
              </Typography>
            ))}
          </Breadcrumbs>
        ) : (
          []
        )}
        {love ? (
          <i class="fas fa-heart p-right" style={{ color: "#e91e63" }}></i>
        ) : (
          []
        )}
        {career.title}
        {career.quizWeight && showMatch ? (
          <Typography
            variant="caption"
            display="block"
            style={{
              padding: "1px 4px",
              marginTop: 4,
              border: "solid 1px rgba(155,155,155,0.3)",
              borderRadius: 8,
              display: "inline-block",
              marginLeft: 4,
            }}
          >{`${career.quizWeight}% Match`}</Typography>
        ) : (
          []
        )}
        {variant === "inline" && career?.description ? (
          <Typography display="block" variant="body2">
            <i>{career.oneSentenceDescription}</i>
          </Typography>
        ) : (
          []
        )}
        {/* {variant === "inline" && career?.skills?.length ? (
          <>
            <Divider />
            <Typography
              display="block"
              variant="caption"
              style={{ fontWeight: 700 }}
            >
              Skills:{" "}
              {career?.skills
                .reduce((a, v) => {
                  a.push(v.value);
                  return a;
                }, [])
                .join(",")}
            </Typography>
          </>
        ) : (
          []
        )} */}
      </Typography>
    </div>
  );
}
