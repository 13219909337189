export default (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  kimi: {
    fontFamily: "Kimi",
    color: "#37474f",
    lineHeight: 1,
  },
});
