import { Avatar, Paper, Popover, Typography } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function AuthorCard({ author }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Paper
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        elevation={0}
        className={classes.container}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Avatar style={{ marginRight: 8 }} src={author.picture} />
        <Typography>{author.name} </Typography>
      </Paper>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2">
          <div
            className={classes.muse}
            dangerouslySetInnerHTML={{ __html: author.bio }}
          />
        </Typography>
      </Popover>
    </div>
  );
}
