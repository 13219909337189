import {
  SET_DARK_MODE,
  SET_FAVORITE_OPEN,
  SET_MY_QUIZES_OPEN,
  SET_SEARCH_OPEN,
} from "constants/app";

export default (
  state = {
    darkMode: false,
    favoriteOpen: false,
    myQuizzesOpen: false,
    searchOpen: false,
  },
  action
) => {
  switch (action.type) {
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    case SET_FAVORITE_OPEN:
      return { ...state, favoriteOpen: action.favoriteOpen };
    case SET_SEARCH_OPEN:
      return { ...state, searchOpen: action.searchOpen };
    case SET_MY_QUIZES_OPEN:
      return { ...state, myQuizzesOpen: action.myQuizzesOpen };
    default:
      return state;
  }
};
