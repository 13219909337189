import { Grid } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tilt from "hooks/Tilt";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function FeatureCareerCard({ cardWidth, career, onClick }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const careerPreferences = useSelector((state) => state.careerPreferences);

  return (
    <Grid item key={career} style={{ width: cardWidth, height: "100%" }}>
      <div
        style={{ height: "100%", display: "flex" }}
        onClick={onClick(career)}
      >
        <Tilt
          style={{
            background: career?.categories && career?.categories[0]?.color,
            margin: 16,
            width: cardWidth || "100%",
            borderRadius: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative",
            padding: 12,
          }}
        >
          {careerPreferences[career.id]?.love ? (
            <div style={{ position: "absolute", top: 8, left: 8 }}>
              <i class="fas fa-heart p-right" style={{ color: "#e91e63" }} />
            </div>
          ) : (
            []
          )}
          {career.quizWeight ? (
            <Typography
              variant="caption"
              display="block"
              style={{
                padding: "1px 4px",
                marginTop: 4,
                border: "solid 1px rgba(155,155,155,0.3)",
                background: "rgba(255,255,255,0.8)",
                borderRadius: 8,
                display: "inline-block",
                marginLeft: 4,
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >{`${career.quizWeight}% Match`}</Typography>
          ) : (
            []
          )}
          <img style={{ height: 140 }} src={career.icon} />
          <Typography
            variant="h6"
            style={{
              fontFamily: "cooper-black-std, serif",
              textAlign: "center",
            }}
          >
            {career.title}
          </Typography>
          <Typography style={{ textAlign: "center" }} variant="body2">
            {career.oneSentenceDescription}
          </Typography>
        </Tilt>
      </div>
    </Grid>
  );
}
