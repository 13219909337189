// import PropTypes from 'prop-types';
// material-ui
import {
  Avatar,
  Badge,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import CareerBubble from "hooks/CareerBubble";
import SubCategoryTree from "hooks/SubCategoryTree";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CategoryTree({ category, last }) {
  const [careers, setCareers] = React.useState();
  const [expanded, setExpanded] = React.useState(category.id === 3);
  const [notForMe, setNotForMe] = React.useState(false);
  const [love, setLove] = React.useState(false);
  const fullApp = useSelector((state) => state.fullApp);
  const classes = useStyles(styles);
  const categoryPreferences = useSelector((state) => state.categoryPreferences);
  const selectedCategory = useSelector((state) => state.selectedCategory);

  React.useEffect(() => {
    if (selectedCategory && selectedCategory.id === category.id) {
      setExpanded(true);
    }
  }, [selectedCategory]);

  React.useEffect(() => {
    const _c = fullApp?.reduce((a, v) => {
      if (v?.categories?.find((i) => i.id === category.id)) {
        a.push(v);
      }
      return a;
    }, []);
    setCareers(_c);
  }, [fullApp]);

  React.useEffect(() => {
    const preference = categoryPreferences[category.id];

    if (preference && preference.notForMe !== notForMe) {
      setNotForMe(preference.notForMe);
      if (preference.notForMe) setExpanded(false);
    }

    if (preference && preference?.love !== love) {
      setLove(preference.love);
      if (preference.love && notForMe && !expanded) setExpanded(true);
    }
  }, [categoryPreferences]);

  function handleExpand() {
    setExpanded((p) => !p);
  }

  let color = category.color;
  if (notForMe) color = "#9e9e9e";

  return (
    <div
      className={classes.root}
      style={{
        borderColor: color,
        borderLeftStyle: notForMe ? "dashed" : "solid",
        position: "relative",
      }}
      id={`CategoryTree_${category.id}`}
    >
      {last ? (
        <div
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            bottom: 0,
            left: -12,
            borderRadius: 10,
            background: color,
          }}
        />
      ) : (
        []
      )}
      <Badge
        badgeContent={
          love ? (
            <i
              class="fas fa-heart"
              style={{ color: "#e91e63", position: "absolute" }}
            ></i>
          ) : (
            []
          )
        }
      >
        <Avatar className={classes.avatar} style={{ background: color }}>
          <i className={category.icon}></i>
        </Avatar>
      </Badge>
      <div style={{ padding: "8px 0px 0px 20px" }}>
        <div style={{ cursor: "pointer" }} onClick={handleExpand}>
          <Typography
            variant="h4"
            classes={{ h4: classes.kimi }}
            style={{ lineHeight: 1 }}
          >
            {category.title}{" "}
            <IconButton size="small">
              <Icon
                style={{
                  transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform .2s",
                }}
                fontSize="inherit"
                className="fad fa-chevron-down"
              />
            </IconButton>
          </Typography>
        </div>
        <Typography variant="body2" style={{ opacity: 0.58 }}>
          {careers?.length} Career{careers?.length > 1 ? "s" : ""}
        </Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {category?.subCategories?.map((subC) => (
            <SubCategoryTree
              category={category}
              color={color}
              subCategory={subC}
              key={subC.id}
            />
          ))}
          {!category?.subCategories ? (
            <Grid container>
              {careers?.map((c) => (
                <CareerBubble key={c.id} career={c} color={color} />
              ))}
            </Grid>
          ) : (
            []
          )}
        </Collapse>
      </div>
    </div>
  );
}
