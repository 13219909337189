// import PropTypes from 'prop-types';
// material-ui
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/image/NewCareerLogo-Gradient.png";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CPFMLogo() {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <div style={{ textAlign: "center" }}>
      <Hidden xsDown>
        <img style={{ height: 100, paddingRight: 16 }} src={logo} alt="cpfm" />
      </Hidden>
      <Hidden smUp>
        <img style={{ height: 80, paddingRight: 16 }} src={logo} alt="cpfm" />
      </Hidden>
    </div>
  );
}
