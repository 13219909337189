export default (theme) => ({
  container: {
    border: "solid 2px black",
    display: "flex",
    alignItems: "center",
    padding: "0px 4px",
  },
  paper: {
    margin: 16,
    height: "calc(100% - 40px)",
    borderRadius: 4,
    padding: 0,
  },
  paperSm: {
    height: "100%",
    padding: 0,
  },
});
