// import PropTypes from 'prop-types';
// material-ui
import { Button, Typography } from "@material-ui/core";
import * as Actions from "actions";
import openInGraph from "assets/image/showInGraph.png";
import React from "react";
import { useDispatch } from "react-redux";

export default function ShowInGraph({ career, quiz }) {
  const dispatch = useDispatch();

  function showCareerInGraph() {
    dispatch(Actions.setSelectedCareer());
    dispatch(Actions.setSelectedQuizOnGraph());
    dispatch(Actions.setFavoriteOnly());
    dispatch(Actions.setGraphOpen(true));
    setTimeout(() => dispatch(Actions.setSelectedCareerOnGraph(career)), 1000);
  }

  function showQuizInGraph() {
    dispatch(Actions.setGraphOpen(true));
    setTimeout(() => {
      dispatch(Actions.setSelectedQuizOnGraph(quiz));
      setTimeout(() => {
        dispatch(Actions.setGuidedTourPlaying(true));
      }, 1000);
    }, 1000);
  }

  if (quiz)
    return (
      <Button
        variant="outlined"
        size="small"
        onClick={showQuizInGraph}
        style={{ width: 250, background: "white", height: 150 }}
      >
        <img height={50} src={openInGraph} style={{ marginRight: 8 }} />
        <Typography variant="h6">
          Start a Graph
          <br />
          <span style={{ fontFamily: "cooper-black-std, serif" }}>
            Guided Tour
          </span>
        </Typography>
      </Button>
    );

  if (career)
    return (
      <Button
        variant="outlined"
        size="small"
        onClick={showCareerInGraph}
        style={{ width: 140, background: "white" }}
      >
        <img height={25} src={openInGraph} style={{ marginRight: 4 }} />
        Show in Graph
      </Button>
    );

  return <div />;
}
