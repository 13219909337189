export default (theme) => ({
  container: {
    padding: 5,
  },
  chip: {
    margin: 5,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  textField: {
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  muse: {
    fontFamily: "museo-sans, sans-serif !important",
  },
  paper: {
    margin: 16,
    height: "calc(100% - 40px)",
    borderRadius: 4,
    padding: 0,
  },
  paperSm: {
    height: "100%",
    padding: 0,
  },
  container1: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container2: {
    maxWidth: 920,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px 0px 10px",
  },
  dialogContent: {
    display: "flex",
    position: "relative",
    padding: 0,
  },
  loadingProgessContainer: {
    flex: 1,
    height: "20%",
  },
  gridItemCenter: {
    textAlign: "center",
  },
  tag: {
    padding: 2,
    margin: 6,
    border: "solid 3px rgba(155,155,155,0.3)",
    background: "rgba(155,155,155,0.1)",
    borderRadius: 100,
    height: 100,
    width: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  },
  submitButton: {
    marginTop: 20,
    color: "black",
    fontSize: 20,
    borderColor: "white",
    background: "#F6C47D",
    width: "100%",
  },
});
