import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const studentQuizzes = `${REACT_APP_API_PREFIX}/${service}/studentQuizzes`;

export function getStudentQuizzes(filters) {
  const url = `${studentQuizzes}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMyStudentQuizzes() {
  const url = `${studentQuizzes}/my`;
  return {
    method: GET,
    url,
  };
}

export function getStudentQuizzById(id) {
  const url = `${studentQuizzes}/${id}/careers`;
  return {
    method: GET,
    url,
  };
}

export function updateStudentQuizz(id, body) {
  const url = `${studentQuizzes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudentQuizz(id) {
  const url = `${studentQuizzes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudentQuizz(body) {
  const url = `${studentQuizzes}/careers`;
  return {
    method: POST,
    url,
    body,
  };
}

export function createSyncedStudentQuizz(body) {
  const url = `${studentQuizzes}`;
  return {
    method: POST,
    url,
    body,
  };
}
