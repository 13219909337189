// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import CareerBubble from "hooks/CareerBubble";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CPFMFavorite() {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const careerPreferences = useSelector((state) => state.careerPreferences);
  const fullApp = useSelector((state) => state.fullApp);

  console.log(careerPreferences);
  return (
    <div>
      {Object.keys(careerPreferences).map((c) => {
        const career = fullApp.find((ca) => ca.id === careerPreferences[c].id);
        return (
          <div style={{ marginTop: 6 }}>
            <CareerBubble
              key={career.id}
              career={career}
              color={career.categories[0].color}
              variant="inline"
            />
          </div>
        );
      })}
    </div>
  );
}
