import {
  Breadcrumbs,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { getGradient } from "helpers";
import AuthorCard from "hooks/AuthorCard";
import Footer from "hooks/Footer";
import Carousel from "nuka-carousel";
import React from "react";
import ReactPlayer from "react-player";
import CategoryAvatar from "./CategoryAvatar";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function Career(props) {
  const {
    title,
    titles,
    authors,
    description,
    careerCategories,
    categories,
    oneSentenceDescription,
    careerSliderItems,
    careerDegreeRequirements,
    additionalInfo,
    apprenticeship,
    intership,
    icon,
    careerDegreeLevels,
    careerMedia,
  } = props;

  const classes = useStyles(styles);

  const _categories = careerCategories || categories;
  const gradient = getGradient(_categories);

  return (
    <div style={{ overflow: "scroll", margin: "auto" }}>
      <div
        style={{
          padding: 20,
          paddingBottom: 40,
          maxWidth: 600,
          margin: "auto",
          position: "relative",
          overflow: "hidden",
          fontFamily: "museo-sans, sans-serif",
        }}
      >
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" separator="•">
              {_categories?.map((cc) => (
                <Typography variant="body2" className={classes.muse}>
                  {cc?.title}
                </Typography>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            style={{
              flexGrow: 1,
              width: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              style={{
                fontFamily: "cooper-black-std, serif",
                wordWrap: "break-word",
              }}
            >
              {title}
            </Typography>
            {titles ? (
              <Typography variant="body2" style={{ paddingBottom: 2 }}>
                Also known as{" "}
                {titles
                  .reduce((a, v) => {
                    a.push(v.title);
                    return a;
                  }, [])
                  .join(", ")}
              </Typography>
            ) : (
              []
            )}
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <CategoryAvatar
              gap={15}
              icon={icon}
              categories={_categories ?? []}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: -12,
              maxWidth: `calc(100% - ${_categories?.length * 20 + 24}px)`,
            }}
          >
            <div
              style={{
                background: gradient,
                height: 3,
              }}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          style={{
            background: _categories && _categories[0]?.color,
            marginTop: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 16px",
            textAlign: "center",
          }}
        >
          <b>{oneSentenceDescription}</b>
        </Typography>
        <br />
        <Typography variant="body2">
          <div
            className={classes.muse}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Typography>
        {careerSliderItems && careerSliderItems.length ? (
          <div style={{ height: 450, width: "100%", position: "relative" }}>
            <Carousel
              slidesToShow={1}
              frameOverflow
              withoutControls={careerSliderItems.length > 0}
              cellSpacing={20}
              enableKeyboardControls
            >
              {careerSliderItems
                .sort((a, b) => a.order - b.order)
                .map((c) => (
                  <>
                    {c.type === "image" ? (
                      <div
                        style={{
                          height: 450,
                          width: "100%",
                          position: "relative",
                          background: "black",
                          textAlign: "center",
                        }}
                      >
                        <img
                          height="100%"
                          alt="careerSliderElement"
                          src={c.src}
                        />
                      </div>
                    ) : (
                      []
                    )}
                    {c.type === "video" ? (
                      <div
                        style={{
                          width: "100%",
                          height: 450,
                          position: "relative",
                          background: "black",
                        }}
                      >
                        <ReactPlayer
                          url={c.src}
                          key={c.id}
                          controls
                          light
                          height="100%"
                          width="100%"
                        />
                      </div>
                    ) : (
                      []
                    )}
                  </>
                ))}
            </Carousel>
          </div>
        ) : (
          []
        )}
        {careerMedia &&
          careerMedia.map((cm, i) => (
            <Grid
              container
              spacing={1}
              style={{
                margin: "20px 0px",
                position: "relative",
              }}
              direction={Number(i) % 2 === 0 ? "row" : "row-reverse"}
            >
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  position: "relative",
                }}
              >
                <div className="player-wrapper">
                  <ReactPlayer
                    url={cm.mediaURL}
                    className="react-player"
                    key={cm.id}
                    controls
                    light
                    width="100%"
                    height={180}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h6"
                  display="block"
                  style={{
                    fontFamily: "cooper-black-std, serif",
                  }}
                >
                  {cm?.title}
                </Typography>
                <Typography className={classes.muse} variant="body2">
                  {cm?.description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {careerDegreeLevels.length ? (
          <Paper variant="outlined" style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="body1" className={classes.heading}>
              What are the education requirements?
            </Typography>
            <ul>
              {careerDegreeRequirements?.map((cs) => (
                <li>
                  <Typography variant="body2">{cs.value}</Typography>
                </li>
              ))}
            </ul>
          </Paper>
        ) : (
          []
        )}
        {careerDegreeLevels.length ? (
          <Paper variant="outlined" style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="body1" className={classes.heading}>
              What degree is recommended?
            </Typography>
            <ul>
              {careerDegreeLevels?.map((cs) => (
                <li>
                  <Typography variant="body2">{cs.value}</Typography>
                </li>
              ))}
            </ul>
          </Paper>
        ) : (
          []
        )}
        {apprenticeship || intership ? (
          <Paper variant="outlined" style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="body1" className={classes.heading}>
              Are internships or apprenticeships recommended?
            </Typography>
            <ul>
              {apprenticeship ? (
                <li>
                  <Typography variant="body2">
                    Apprenticeship {apprenticeship ? "is" : "is not"}{" "}
                    recommended.
                  </Typography>
                </li>
              ) : (
                []
              )}
              {intership ? (
                <li>
                  <Typography variant="body2">
                    Internship {intership ? "is" : "is not"} recommended.
                  </Typography>
                </li>
              ) : (
                []
              )}
            </ul>
          </Paper>
        ) : (
          []
        )}
        {additionalInfo ? (
          <Paper variant="outlined" style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="body1" className={classes.heading}>
              Additional Info
            </Typography>
            <Typography variant="body2">
              <div dangerouslySetInnerHTML={{ __html: additionalInfo }} />
            </Typography>
          </Paper>
        ) : (
          []
        )}
        {authors?.length ? (
          <div style={{ marginTop: 16 }}>
            <Typography variant="overline">
              Author{authors.length > 1 ? "s" : ""}
            </Typography>
            <Grid container>
              {authors.map((a) => (
                <Grid item>
                  <AuthorCard author={a} />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          []
        )}
      </div>
      <Footer />
    </div>
  );
}
