import { GET_CURRENT_USER, SIGNIN } from "constants/users";

export default (state = null, action) => {
  let user;
  if (action.payload) {
    user = action.payload;
  }

  switch (action.type) {
    case `${GET_CURRENT_USER}_SUCCESS`:
    case `${SIGNIN}_SUCCESS`:
      return user;
    default:
      return state;
  }
};
