import { SET_CATEGORY_PREFERENCES } from "constants/app";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CATEGORY_PREFERENCES:
      const _state = { ...state };
      const preference = action.value;
      _state[preference.id] = action.value;
      return _state;
    default:
      return state;
  }
};
