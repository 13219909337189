import { networkAction } from 'helpers/network/networkAction';

import {
  GET_STUDENT_CAREERS,
  GET_STUDENT_CAREER_BY_ID,
  DELETE_STUDENT_CAREER,
  UPDATE_STUDENT_CAREER,
  CREATE_STUDENT_CAREER,
} from 'constants/studentCareers';

import * as Api from 'api';

export const getStudentCareers = () => async dispatch => networkAction(
  dispatch,
  GET_STUDENT_CAREERS,
  Api.getStudentCareers,
  []
);

export const getStudentCareerById = (id) => async dispatch => networkAction(
  dispatch,
  GET_STUDENT_CAREER_BY_ID,
  Api.getStudentCareerById,
  [id]
);

export const deleteStudentCareer = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_STUDENT_CAREER,
  Api.deleteStudentCareer,
  [id]
);

export const createStudentCareer = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_STUDENT_CAREER,
  Api.createStudentCareer,
  [body]
);

export const updateStudentCareer = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_STUDENT_CAREER,
  Api.updateStudentCareer,
  [id, body]
);
