import { makeStyles } from "@material-ui/core";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CategoryAvatar({ icon, categories, gap = 20 }) {
  return (
    <div style={{ position: "relative", display: "flex" }}>
      {categories.map((c, i) => (
        <div
          style={{
            height: 100,
            width: Number(i) !== categories.length - 1 ? gap : 100,
          }}
        >
          <div
            style={{
              height: 100,
              width: 100,
              borderRadius: 50,
              background: c?.color,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {i === categories.length - 1 ? (
              <img src={icon} height="80px" width="80px" alt="icon" />
            ) : (
              []
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
