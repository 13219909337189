export default (theme) => ({
  root: {
    padding: "5px 16px",
    width: 100,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  kimi: {
    fontFamily: "Kimi",
  },
  muse: {
    fontFamily: "museo-sans, sans-serif !important",
  },
});
