export default (theme) => {
  const darkMode = theme.palette.type === "dark";
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      flex: 1,
      background: "#F6F9FC",
    },
    content: {
      flex: 1,
      display: "flex",
    },
    navigation: {
      position: "fixed",
      bottom: 0,
      width: "100vw",
      background: "rgba(255,255,255,0.8)",
      backdropFilter: "blur(10px)",
      WebkitBackdropFilter: "blur(10px)",
    },
    kimi: {
      fontFamily: "Kimi",
    },
  };
};
