import { Badge, FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 10px)",
    margin: "auto",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase() {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [result, setResult] = React.useState([]);
  const [resultWithTags, setResultWithTags] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSkillTags, setSelectedSkillTags] = React.useState([]);
  const fullApp = useSelector((state) => state.fullApp);
  const skillTags = useSelector((state) => state.skillTags);

  React.useEffect(() => {
    if (fullApp) {
      setResult(fullApp);
    }
  }, [fullApp]);

  React.useEffect(() => {
    if (result && selectedSkillTags.length > 1) {
      applyTagsToResult(selectedSkillTags);
    }
  }, [result]);

  function handleSearch(e) {
    const t = e.target.value;
    setSearchText(t);
    const r = fullApp.filter(
      (c) =>
        `${c.title}`.toLowerCase().indexOf(`${t}`.toLowerCase()) !== -1 ||
        c.titles?.reduce(
          (a, v) =>
            a || `${v}`.toLowerCase().indexOf(`${t}`.toLowerCase()) !== -1,
          false
        ) ||
        c.skills?.reduce(
          (a, v) =>
            a ||
            `${v.value}`.toLowerCase().indexOf(`${t}`.toLowerCase()) !== -1,
          false
        )
    );

    setResult(r);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSkillTagChecked = (s) => () => {
    const _selectedSkillTags = [...selectedSkillTags];
    const index = _selectedSkillTags?.findIndex((e) => e.id === s.id);
    if (index !== -1) {
      _selectedSkillTags.splice(index, 1);
    } else {
      _selectedSkillTags.push(s);
    }

    setSelectedSkillTags(_selectedSkillTags);
    applyTagsToResult(_selectedSkillTags);
  };

  function applyTagsToResult(tags) {
    if (tags.length === 0) return;
    let _result = [];

    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const _career = result[key];
        let included = false;
        if (_career.skills) {
          for (const key in _career.skills) {
            if (Object.hasOwnProperty.call(_career.skills, key)) {
              const _careerSkill = _career.skills[key];
              if (tags.find((t) => _careerSkill.id === t.id)) {
                included = true;
              }
            }
          }
        }
        if (included) _result.push(_career);
      }
    }

    setResultWithTags(_result);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ position: "relative", border: "solid 2px black" }}>
      <div component="form" className={classes.root}>
        <InputBase
          value={searchText}
          onChange={handleSearch}
          className={classes.input}
          inputProps={{ "aria-label": "search google maps" }}
        />
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon style={{ color: "black" }} />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <Tooltip title="Filter">
          <Badge
            badgeContent={
              selectedSkillTags.length && (
                <span style={{ color: "white" }}>
                  {selectedSkillTags.length}
                </span>
              )
            }
            color="error"
          >
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="directions"
              onClick={handleClick}
              size="small"
            >
              <Icon
                className="fas fa-filter"
                fontSize="inherit"
                style={{ color: "black" }}
              />
            </IconButton>
          </Badge>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ marginTop: 16 }}
        >
          <FormGroup
            row
            style={{ maxWidth: 580, width: "calc(100vw - 20px)", padding: 10 }}
          >
            {skillTags?.map((s) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedSkillTags.find((e) => e.id === s.id) !== undefined
                    }
                    onChange={handleSkillTagChecked(s)}
                    name="checkedA"
                  />
                }
                label={s.value}
              />
            ))}
          </FormGroup>
        </Popover>
      </div>
      {/* {(selectedSkillTags.length > 0 ? resultWithTags : result).map((c) => (
        <div style={{ marginTop: 6 }}>
          <CareerBubble
            key={c.id}
            career={c}
            color={c.categories[0].color}
            variant="inline"
          />
        </div>
      ))} */}
    </div>
  );
}
