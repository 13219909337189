// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function NewsItem({ title, body, comingSoon }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <div className={classes.container}>
      <Typography
        variant="body1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <b>{title}</b>
        {comingSoon && <span className="comingSoon">Coming Soon</span>}
      </Typography>
      <Typography variant="body2">{body}</Typography>
    </div>
  );
}
