import { Grid } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import CategoryTree from "hooks/CategoryTree";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CategoriesTree() {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const categories = useSelector((state) => state.cpfmCategories);

  return (
    <Grid container>
      {categories?.map((c, i) => (
        <Grid item xs={12} key={c.id}>
          <CategoryTree
            category={c}
            last={Number(i) === categories.length - 1}
          />
        </Grid>
      ))}
    </Grid>
  );
}
