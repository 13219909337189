// import PropTypes from 'prop-types';
// material-ui
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import * as Actions from "actions";
import CIMFooterGradientOnly from "assets/image/CIMFooterGradientOnly.jpg";
import FeatureCareerCard from "hooks/FeatureCareerCard";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function FavoriteCareers() {
  const favoriteOpen = useSelector((state) => state.app.favoriteOpen);
  const fullApp = useSelector((state) => state.fullApp);
  const careerPreferences = useSelector((state) => state.careerPreferences);
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  function close() {
    dispatch(Actions.setFavoriteOpen(false));
  }

  const favoriteCareers = useMemo(() => {
    const res = [];

    if (careerPreferences && fullApp) {
      for (const key in careerPreferences) {
        if (Object.hasOwnProperty.call(careerPreferences, key)) {
          const preference = careerPreferences[key];
          if (preference.love) {
            res.push(fullApp.find((c) => `${c.id}` === `${key}`));
          }
        }
      }
    }

    return res;
  }, [careerPreferences, fullApp]);

  const handleCareerClick = (c) => () => {
    dispatch(Actions.setSelectedCareer(c));
  };

  return (
    <Dialog
      open={favoriteOpen}
      onClose={close}
      fullScreen
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: mobile ? classes.paperSm : classes.paper,
      }}
    >
      <div
        onClick={close}
        style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}
      >
        <IconButton>
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <div
          style={{
            backgroundImage: `url(${CIMFooterGradientOnly})`,
            backgroundSize: "cover",
            padding: 68,
            borderRadius: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 16,
            marginRight: 32,
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: "cooper-black-std, serif",
              textAlign: "center",
            }}
          >
            Explore your favorites!
          </Typography>
        </div>
        <Grid container alignItems="stretch">
          {favoriteCareers.map((c) => (
            <Grid item xs={12} md={4} lg={3}>
              <FeatureCareerCard career={c} onClick={handleCareerClick} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
