import { SEARCH_IN_FULL_APP } from "constants/cpfmCategories";

export default (state = [], action) => {
  switch (action.type) {
    case `${SEARCH_IN_FULL_APP}_SUCCESS`:
      const res = [];
      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          const career = action.payload[key];
          career.categories = [...new Set(career.categories)];
          res.push(career);
        }
      }
      return res;
    default:
      return state;
  }
};
