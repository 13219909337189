import { RESTART_QUIZ } from "constants/app";
import { GET_FULL_APP } from "constants/cpfmCategories";

export default (state = null, action) => {
  switch (action.type) {
    case `${GET_FULL_APP}_SUCCESS`:
      const res = [];
      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          const career = action.payload[key];
          career.categories = [...new Set(career.categories)];
          res.push(career);
        }
      }
      return res;
    case RESTART_QUIZ:
      const _s = [...state];
      for (const key in _s) {
        if (Object.hasOwnProperty.call(_s, key)) {
          delete _s[key].quizWeight;
        }
      }
      return _s;
    // case `${CREATE_STUDENT_QUIZZ}_SUCCESS`:
    //   const _state = [...state];
    //   for (const key in action.payload) {
    //     if (Object.hasOwnProperty.call(action.payload, key)) {
    //       const career = action.payload[key];
    //       const index = _state?.findIndex((c) => c?.id === career?.id);
    //       if (index >= 0) {
    //         _state[index].quizWeight = career.quizWeight;
    //       }
    //     }
    //   }
    //   return _state;
    default:
      return state;
  }
};
