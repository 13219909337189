// import PropTypes from 'prop-types';
// material-ui
import { fade, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tilt from "hooks/Tilt";
import React, { useEffect, useRef, useState } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function AnswerBubble({ tag, selectedQuizTags, color }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const { fontSize, ref } = useFitText();
  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const selected = selectedQuizTags.indexOf(tag) !== -1;

  return (
    <Tilt>
      <div
        ref={ref}
        className={mobile ? classes.tagMobile : classes.tag}
        style={{
          borderColor: color,
          color: "black",
          boxShadow: selected ? `inset 0px 6px 8px 0px ${color}` : undefined,
          background: !selected ? color : fade(color, 0.1),
        }}
      >
        <div
          id={tag.id}
          style={{
            margin: mobile ? 8 : 10,
            height: 70,
            width: 70,
            fontSize: fontSize,
            transform: mobile ? "scale(0.8)" : "scale(0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div ref={ref}>{tag.name}</div>
        </div>
      </div>
    </Tilt>
  );
}

const useFitText = () => {
  const MIN_FONT_SIZE = 20;
  const MAX_FONT_SIZE = 100;
  const RESOLUTION = 5;

  const ref = useRef(null);

  const [state, setState] = useState({
    fontSize: MAX_FONT_SIZE,
    fontSizePrev: MIN_FONT_SIZE,
    fontSizeMax: MAX_FONT_SIZE,
    fontSizeMin: MIN_FONT_SIZE,
  });
  const { fontSize, fontSizeMax, fontSizeMin, fontSizePrev } = state;

  useEffect(() => {
    const isDone = Math.abs(fontSize - fontSizePrev) <= RESOLUTION;
    const isOverflow =
      !!ref.current &&
      (ref.current.scrollHeight > ref.current.offsetHeight ||
        ref.current.scrollWidth > ref.current.offsetWidth);
    const isAsc = fontSize > fontSizePrev;

    // return if the font size has been adjusted "enough" (change within RESOLUTION)
    // reduce font size by one increment if it's overflowing
    if (isDone) {
      if (isOverflow) {
        const fontSizeNew =
          fontSizePrev < fontSize
            ? fontSizePrev
            : fontSize - (fontSizePrev - fontSize);
        setState({
          fontSize: fontSizeNew,
          fontSizeMax,
          fontSizeMin,
          fontSizePrev,
        });
      }
      return;
    }

    // binary search to adjust font size
    let delta;
    let newMax = fontSizeMax;
    let newMin = fontSizeMin;
    if (isOverflow) {
      delta = isAsc ? fontSizePrev - fontSize : fontSizeMin - fontSize;
      newMax = Math.min(fontSizeMax, fontSize);
    } else {
      delta = isAsc ? fontSizeMax - fontSize : fontSizePrev - fontSize;
      newMin = Math.max(fontSizeMin, fontSize);
    }
    setState({
      fontSize: fontSize + delta / 2,
      fontSizeMax: newMax,
      fontSizeMin: newMin,
      fontSizePrev: fontSize,
    });
  }, [fontSize, fontSizeMax, fontSizeMin, fontSizePrev, ref]);

  return { fontSize: `${fontSize}%`, ref };
};
