import * as Api from "api";
import { SET_QUIZ_OPEN } from "constants/app";
import {
  CREATE_STUDENT_QUIZZ,
  CREATE_SYNCED_STUDENT_QUIZZ,
  DELETE_STUDENT_QUIZZ,
  GET_MY_STUDENT_QUIZZES,
  GET_STUDENT_QUIZZES,
  GET_STUDENT_QUIZZ_BY_ID,
  UPDATE_STUDENT_QUIZZ,
} from "constants/studentQuizzes";
import { networkAction } from "helpers/network/networkAction";

export const setQuizOpen = (quizOpen) => async (dispatch) =>
  dispatch({
    type: SET_QUIZ_OPEN,
    quizOpen,
  });

export const getStudentQuizzes = () => async (dispatch) =>
  networkAction(dispatch, GET_STUDENT_QUIZZES, Api.getStudentQuizzes, []);

export const getMyStudentQuizzes = () => async (dispatch) =>
  networkAction(dispatch, GET_MY_STUDENT_QUIZZES, Api.getMyStudentQuizzes, []);

export const getStudentQuizzById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STUDENT_QUIZZ_BY_ID, Api.getStudentQuizzById, [
    id,
  ]);

export const deleteStudentQuizz = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STUDENT_QUIZZ, Api.deleteStudentQuizz, [id]);

export const createStudentQuizz = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STUDENT_QUIZZ, Api.createStudentQuizz, [body]);

export const createSyncedStudentQuizz = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_SYNCED_STUDENT_QUIZZ,
    Api.createSyncedStudentQuizz,
    [body]
  );

export const updateStudentQuizz = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STUDENT_QUIZZ, Api.updateStudentQuizz, [
    id,
    body,
  ]);
