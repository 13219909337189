import {
  Chip,
  Grid,
  Hidden,
  Icon,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import * as Actions from "actions";
import Career from "hooks/Career/Career";
import ShowInGraph from "hooks/ShowInGraph";
import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CareerView() {
  const [open, setOpen] = React.useState(false);
  const [careerMedia, setCareerMedia] = React.useState([]);
  const [careerAuthors, setCareerAuthors] = React.useState([]);
  const [sliderItems, setSliderItems] = React.useState([]);
  const [
    careerDegreeRequirements,
    setCareerDegreeRequirements,
  ] = React.useState([]);
  const [careerDegreeLevels, setCareerDegreeLevels] = React.useState([]);
  const [notForMe, setNotForMe] = React.useState(false);
  const [love, setLove] = React.useState(false);
  const career = useSelector((state) => state.selectedCareer);
  const dispatch = useDispatch();
  const classes = useStyles(styles);
  const careerPreferences = useSelector((state) => state.careerPreferences);
  const authors = useSelector((state) => state.authors);
  const degreeRequirements = useSelector((state) => state.degreeRequirements);
  const degreeLevels = useSelector((state) => state.degreeLevels);
  const graphOpen = useSelector((state) => state.graph.graphOpen);

  React.useEffect(() => {
    if (career) {
      const preference = careerPreferences[career.id];

      if (preference && preference.notForMe !== notForMe) {
        setNotForMe(preference.notForMe);
      }

      if (preference && preference?.love !== love) {
        setLove(preference.love);
      }
    }
  }, [careerPreferences, career]);

  React.useEffect(() => {
    if (career && career.id) {
      setOpen(true);
      dispatch(
        Actions.getCareerMedia([
          {
            name: "careerID",
            comparison: "eq",
            value: career.id,
          },
        ])
      ).then((r) => {
        setCareerMedia(r.payload);
      });

      dispatch(
        Actions.getCareerAuthors([
          {
            name: "career_authors.careerID",
            comparison: "eq",
            value: career.id,
          },
        ])
      ).then((r) => {
        const _authors = [];
        const payload = r.payload;
        for (const key in payload) {
          if (Object.hasOwnProperty.call(payload, key)) {
            const _careerAuthor = payload[key];
            _authors.push(authors.find((a) => a.id === _careerAuthor.authorID));
          }

          setCareerAuthors(_authors);
        }
      });

      dispatch(
        Actions.getCareerDegreeLevels([
          {
            name: "career_degree_levels.careerID",
            comparison: "eq",
            value: career.id,
          },
        ])
      ).then((r) => {
        setCareerDegreeLevels(r.payload);
      });

      dispatch(
        Actions.getCareerSliderItems([
          {
            name: "career_slider_items.careerID",
            comparison: "eq",
            value: career.id,
          },
        ])
      ).then((r) => {
        setSliderItems(r.payload);
      });

      dispatch(
        Actions.getCareerDegreeRequirements([
          {
            name: "career_degree_requirements.careerID",
            comparison: "eq",
            value: career.id,
          },
        ])
      ).then((r) => {
        setCareerDegreeRequirements(r.payload);
      });
    } else {
      setOpen(false);
      setLove(false);
      setCareerMedia();
      setSliderItems([]);
    }
  }, [career]);

  const handleClose = () => {
    dispatch(Actions.setSelectedCareer(null));
    setOpen(false);
  };

  function handleLove() {
    dispatch(
      Actions.setCareerPreferences({
        id: career.id,
        love: !love,
        notForMe: notForMe ? !notForMe : notForMe,
      })
    );
  }

  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: mobile ? classes.paperSm : classes.paper,
        }}
      >
        {career ? (
          <>
            <Helmet>
              <title>{career.title}</title>
              <meta property="og:title" content={career.title} />
              <meta
                property="og:description"
                content={`${career.oneSentenceDescription}`}
              />
              <meta property="og:image" content={career.icon} />
              <meta
                property="og:url"
                content={`https://careers.collegeprepformusicians.com/?careerID=${career.id}`}
              ></meta>
            </Helmet>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  maxWidth: 920,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 10px 0px 10px",
                }}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Chip
                      size="small"
                      variant={love ? "contained" : "outlined"}
                      onClick={handleLove}
                      style={{
                        background: love ? "#e91e63" : "white",
                        color: love ? "white" : undefined,
                      }}
                      label={
                        <span>
                          {!love ? (
                            <i class="far fa-heart p-right" />
                          ) : (
                            <i class="fas fa-heart p-right" />
                          )}
                          {!love ? "Love" : "You Love this"}
                        </span>
                      }
                    />
                  </Grid>
                  <Hidden smDown>
                    <Grid item>
                      <FacebookShareButton
                        url={`https://careers.collegeprepformusicians.com/?careerID=${career?.id}`}
                        quote={"Share"}
                        className="Demo__some-network__share-button"
                      >
                        <i style={{ fontSize: 20 }} class="fab fa-facebook"></i>
                      </FacebookShareButton>
                    </Grid>
                    <Grid item>
                      <TwitterShareButton
                        url={`https://careers.collegeprepformusicians.com/?careerID=${career?.id}`}
                        quote={"Share"}
                        className="Demo__some-network__share-button"
                      >
                        <i style={{ fontSize: 20 }} class="fab fa-twitter"></i>
                      </TwitterShareButton>
                    </Grid>
                    <Grid item>
                      <LinkedinShareButton
                        url={`https://careers.collegeprepformusicians.com/?careerID=${career?.id}`}
                        quote={"Share"}
                        className="Demo__some-network__share-button"
                      >
                        <i style={{ fontSize: 20 }} class="fab fa-linkedin"></i>
                      </LinkedinShareButton>
                    </Grid>
                    <Grid item>
                      <TelegramShareButton
                        url={`https://careers.collegeprepformusicians.com/?careerID=${career?.id}`}
                        quote={"Share"}
                        className="Demo__some-network__share-button"
                      >
                        <i style={{ fontSize: 20 }} class="fab fa-telegram"></i>
                      </TelegramShareButton>
                    </Grid>
                    <Grid item>
                      <WhatsappShareButton
                        url={`https://careers.collegeprepformusicians.com/?careerID=${career?.id}`}
                        quote={"Share"}
                        className="Demo__some-network__share-button"
                      >
                        <i
                          style={{ fontSize: 20 }}
                          class="fab fa-whatsapp-square"
                        ></i>
                      </WhatsappShareButton>
                    </Grid>
                  </Hidden>
                </Grid>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!graphOpen ? <ShowInGraph career={career} /> : []}
                  <IconButton onClick={handleClose}>
                    <Icon className="fal fa-times" />
                  </IconButton>
                </div>
              </div>
            </div>
            <DialogContent style={{ padding: 0 }}>
              <Career
                {...career}
                careerSliderItems={sliderItems}
                careerMedia={careerMedia}
                authors={careerAuthors}
                careerDegreeRequirements={degreeRequirements.filter(
                  (dr) =>
                    careerDegreeRequirements?.findIndex(
                      (cdr) => cdr.degreeRequirementID === dr.id
                    ) !== -1
                )}
                careerDegreeLevels={degreeLevels
                  .filter(
                    (dr) =>
                      careerDegreeLevels?.findIndex(
                        (cdr) => cdr.degreeLevelID === dr.id
                      ) !== -1
                  )
                  .sort((a, b) => {
                    if (b.pos < a.pos) return 1;
                    if (b.pos > a.pos) return -1;
                    return 0;
                  })}
              />
            </DialogContent>
          </>
        ) : (
          []
        )}
      </Dialog>
    </>
  );
}
