import { Grid } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CIMFooterGradientOnly from "assets/image/CIMFooterGradientOnly.jpg";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Footer({ gradient = false }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        padding: "64px 0px",
        // background: "red",
        backgroundImage: gradient && `url(${CIMFooterGradientOnly})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ marginRight: 16 }} variant="h6">
          Brought to you by
        </Typography>
        <a
          href="www.collegeprepformusicians.com"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <img
            src="https://storage.googleapis.com/college101-logos/cpfm/logo_main.png"
            height="60px"
            alt="cpfmLogo"
          />
        </a>
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="body2">
          <a
            href="www.collegeprepformusicians.com"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            www.collegeprepformusicians.com
          </a>
        </Typography>
        <Typography variant="body2">@collegeprepformusicians</Typography>
      </Grid>
    </Grid>
  );
}
