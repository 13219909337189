import * as Api from "api";
import {
  CREATE_CPFM_CATEGORY,
  DELETE_CPFM_CATEGORY,
  GET_CPFM_CATEGORIES,
  GET_CPFM_CATEGORY_BY_ID,
  GET_CPFM_CATEGORY_CAREERS,
  GET_FULL_APP,
  SEARCH_IN_FULL_APP,
  UPDATE_CPFM_CATEGORY,
} from "constants/cpfmCategories";
import { networkAction } from "helpers/network/networkAction";

export const getCpfmCategories = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CPFM_CATEGORIES, Api.getCpfmCategories, [
    filters,
  ]);

export const getFullApp = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_FULL_APP, Api.getFullApp, [filters]);

export const searchInFullApp = (filters) => async (dispatch) =>
  networkAction(dispatch, SEARCH_IN_FULL_APP, Api.getFullApp, [filters]);

export const getCpfmCategoryCareers = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CPFM_CATEGORY_CAREERS,
    Api.getCpfmCategoryCareers,
    [id]
  );

export const getCpfmCategoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CPFM_CATEGORY_BY_ID, Api.getCpfmCategoryById, [
    id,
  ]);

export const deleteCpfmCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CPFM_CATEGORY, Api.deleteCpfmCategory, [id]);

export const createCpfmCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CPFM_CATEGORY, Api.createCpfmCategory, [body]);

export const updateCpfmCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CPFM_CATEGORY, Api.updateCpfmCategory, [
    id,
    body,
  ]);
