import {
  DECREMENT_PLAY_CAREER_INDEX,
  INCREMENT_PLAY_CAREER_INDEX,
  OPEN_SELECTED_CAREER_ON_GRAPH,
  SET_CATEGORIES_ENABLED,
  SET_DAG_MODE,
  SET_FAVORITE_ONLY,
  SET_GRAPH_OPEN,
  SET_GUIDED_TOUR_PLAYING,
  SET_ORBITING,
  SET_PLAY_CAREER_INDEX,
  SET_SELECTED_CAREER_ON_GRAPH,
  SET_SELECTED_QUIZ_ON_GRAPH,
  SET_SHOW_UNSUGGESTED_CAREERS,
  TOGGLE_GUIDED_TOUR_PLAYING,
} from "constants/graph";

export const setDagMode = (dagMode) => async (dispatch) =>
  dispatch({
    type: SET_DAG_MODE,
    dagMode,
  });

export const toggleGuidedTourPlaying = () => async (dispatch) =>
  dispatch({
    type: TOGGLE_GUIDED_TOUR_PLAYING,
  });

export const setFavoriteOnly = (favoriteOnly) => async (dispatch) =>
  dispatch({
    type: SET_FAVORITE_ONLY,
    favoriteOnly,
  });

export const setCategoriesEnabled = (categoriesEnabled) => async (dispatch) =>
  dispatch({
    type: SET_CATEGORIES_ENABLED,
    categoriesEnabled,
  });

export const setGraphOpen = (graphOpen) => async (dispatch) =>
  dispatch({
    type: SET_GRAPH_OPEN,
    graphOpen,
  });
export const setSelectedCareerOnGraph = (selectedCareerOnGraph) => async (
  dispatch
) =>
  dispatch({
    type: SET_SELECTED_CAREER_ON_GRAPH,
    selectedCareerOnGraph,
  });

export const setOrbiting = (orbiting) => async (dispatch) =>
  dispatch({
    type: SET_ORBITING,
    orbiting,
  });

export const setShowUnsuggestedCareers = (showUnsuggestedCareers) => async (
  dispatch
) =>
  dispatch({
    type: SET_SHOW_UNSUGGESTED_CAREERS,
    showUnsuggestedCareers,
  });

export const setSelectedQuizOnGraph = (selectedQuizOnGraph) => async (
  dispatch
) =>
  dispatch({
    type: SET_SELECTED_QUIZ_ON_GRAPH,
    selectedQuizOnGraph,
  });

export const setGuidedTourPlaying = (guidedTourPlaying) => async (dispatch) =>
  dispatch({
    type: SET_GUIDED_TOUR_PLAYING,
    guidedTourPlaying,
  });

export const setPlayCareerIndex = (playCareerIndex) => async (dispatch) =>
  dispatch({
    type: SET_PLAY_CAREER_INDEX,
    playCareerIndex,
  });

export const incrementPlayCareerIndex = () => async (dispatch) =>
  dispatch({
    type: INCREMENT_PLAY_CAREER_INDEX,
  });

export const decrementPlayCareerIndex = () => async (dispatch) =>
  dispatch({
    type: DECREMENT_PLAY_CAREER_INDEX,
  });

export const openSelectedCareerOnGraph = () => async (dispatch) =>
  dispatch({
    type: OPEN_SELECTED_CAREER_ON_GRAPH,
  });
