import {
  SET_SEARCH_COUNT,
  SET_SEARCH_LOADING,
  SET_SEARCH_TEXT,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_DEGREE_LEVELS,
  SET_SELECTED_DEGREE_REQUIREMENTS,
} from "constants/search";

export const setSearchText = (searchText) => async (dispatch) =>
  dispatch({
    type: SET_SEARCH_TEXT,
    searchText,
  });

export const setSearchLoading = (searchLoading) => async (dispatch) =>
  dispatch({
    type: SET_SEARCH_LOADING,
    searchLoading,
  });

export const setSearchCount = (searchCount) => async (dispatch) =>
  dispatch({
    type: SET_SEARCH_COUNT,
    searchCount,
  });

export const setSelectedCategories = (selectedCategories) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_CATEGORIES,
    selectedCategories,
  });

export const setSelectedDegreeLevels = (selectedDegreeLevels) => async (
  dispatch
) =>
  dispatch({
    type: SET_SELECTED_DEGREE_LEVELS,
    selectedDegreeLevels,
  });

export const setSelectedDegreeRequirements = (
  selectedDegreeRequirements
) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_DEGREE_REQUIREMENTS,
    selectedDegreeRequirements,
  });
