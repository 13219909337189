import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import SockJS from "sockjs-client";
import App from "./App";
import "./index.css";
import { unregister } from "./serviceWorker";
import { store } from "./store";

window.SockJS = SockJS;

store.subscribe(() => {
  localStorage.setItem("cpfmState", JSON.stringify(store.getState()));
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
unregister();
