// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Tilt from "react-parallax-tilt";
// styles
import styles from "./styles";
const useStyles = makeStyles(styles);

export default function T({ children, style }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const nav = navigator.userAgent.toLowerCase();
  let safari = false;
  if (nav.indexOf("safari") !== -1) {
    if (nav.indexOf("chrome") > -1) {
    } else {
      safari = true;
    }
  }

  if (safari) return <div style={style}>{children}</div>;

  return (
    <Tilt
      glareEnable
      glareColor="#ffffff"
      glareMaxOpacity={0.8}
      glarePosition="center"
      perspective={2000}
      style={style}
    >
      {children}
    </Tilt>
  );
}
