import { Button, Typography } from "@material-ui/core";
import logo from "assets/image/NewCareerLogo-Gradient.png";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  tryAgain() {
    localStorage.removeItem("cpfmState");
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" />
            <Typography variant="h4">Oooops, something went wrong.</Typography>
            <Typography variant="body2">
              We apologize for this. The error has been reported to the dev
              team.
            </Typography>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.tryAgain.bind(this)}
            >
              Let's try again!
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
