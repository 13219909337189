import { GET_STUDENT_CAREERS } from "constants/studentCareers";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_STUDENT_CAREERS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
