import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const studentCareers = `${REACT_APP_API_PREFIX}/${service}/studentCareers`;

export function getStudentCareers(filters) {
  const url = `${studentCareers}/my`;
  return {
    method: GET,
    url,
  };
}

export function getStudentCareerById(id) {
  const url = `${studentCareers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateStudentCareer(id, body) {
  const url = `${studentCareers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStudentCareer(id) {
  const url = `${studentCareers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStudentCareer(body) {
  const url = `${studentCareers}`;
  return {
    method: POST,
    url,
    body,
  };
}
