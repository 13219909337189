export const SET_CATEGORY_PREFERENCES = "SET_CATEGORY_PREFERENCES";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_SELECTED_CAREER = "SET_SELECTED_CAREER";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_CAREER_PREFERENCES = "SET_CAREER_PREFERENCES";
export const RESTART_QUIZ = "RESTART_QUIZ";
export const SET_SELECTED_QUIZ_ID = "SET_SELECTED_QUIZ_ID";
export const SET_QUIZ_OPEN = "SET_QUIZ_OPEN";
export const SET_FAVORITE_OPEN = "SET_FAVORITE_OPEN";
export const URL_CHANGED_ACTION = "URL_CHANGED_ACTION";
export const SET_MY_QUIZES_OPEN = "SET_MY_QUIZES_OPEN";
export const SET_SEARCH_OPEN = "SET_SEARCH_OPEN";
