import { SET_QUIZ_OPEN, SET_SELECTED_QUIZ_ID } from "constants/app";
import {
  CREATE_STUDENT_QUIZZ,
  CREATE_SYNCED_STUDENT_QUIZZ,
  GET_MY_STUDENT_QUIZZES,
  GET_STUDENT_QUIZZ_BY_ID,
} from "constants/studentQuizzes";

export default (
  state = { selectedQuizID: undefined, all: [], quizOpen: false },
  action
) => {
  switch (action.type) {
    case SET_QUIZ_OPEN:
      return { ...state, quizOpen: action.quizOpen };
    case SET_SELECTED_QUIZ_ID:
      const _s = { ...state };
      _s.selectedQuizID = action.selectedQuizID;
      return _s;
    case `${GET_MY_STUDENT_QUIZZES}_SUCCESS`:
      const _s1 = { ...state };
      _s1.all = action.payload;

      return _s1;
    case `${GET_STUDENT_QUIZZ_BY_ID}_SUCCESS`:
      const _s2 = { ...state };
      const payload = action.payload;
      const index = _s2.all.findIndex((e) => e.id === payload.id);
      if (index >= 0) {
      }

      return _s2;
    case `${CREATE_SYNCED_STUDENT_QUIZZ}_SUCCESS`:
      const _s3 = { ...state };

      _s3.all.push(action.payload);
      _s3.selectedQuizID = action.payload.id;
      return _s3;
    case `${CREATE_STUDENT_QUIZZ}_SUCCESS`:
      const _state = { ...state };
      const dateTaken = new Date().getTime();
      var reducedQuiz = action.payload.reduce((a, v) => {
        a.push({ id: v.id, quizWeight: v.quizWeight });
        return a;
      }, []);

      reducedQuiz.sort((a, b) => b.quizWeight - a.quizWeight);
      const c = {
        id: dateTaken,
        dateTaken,
        careers: reducedQuiz,
      };
      _state.all.push(c);
      _state.selectedQuizID = dateTaken;
      return _state;
    default:
      return state;
  }
};
