export const SET_CATEGORIES_ENABLED = "SET_CATEGORIES_ENABLED";
export const SET_SELECTED_CAREER_ON_GRAPH = "SET_SELECTED_CAREER_ON_GRAPH";
export const SET_ORBITING = "SET_ORBITING";
export const SET_SELECTED_QUIZ_ON_GRAPH = "SET_SELECTED_QUIZ_ON_GRAPH";
export const SET_GUIDED_TOUR_PLAYING = "SET_GUIDED_TOUR_PLAYING";
export const SET_PLAY_CAREER_INDEX = "SET_PLAY_CAREER_INDEX";
export const SET_SHOW_UNSUGGESTED_CAREERS = "SET_SHOW_UNSUGGESTED_CAREERS";
export const INCREMENT_PLAY_CAREER_INDEX = "INCREMENT_PLAY_CAREER_INDEX";
export const DECREMENT_PLAY_CAREER_INDEX = "DECREMENT_PLAY_CAREER_INDEX";
export const OPEN_SELECTED_CAREER_ON_GRAPH = "OPEN_SELECTED_CAREER_ON_GRAPH";
export const SET_GRAPH_OPEN = "SET_GRAPH_OPEN";
export const TOGGLE_GUIDED_TOUR_PLAYING = "TOGGLE_GUIDED_TOUR_PLAYING";
export const SET_FAVORITE_ONLY = "SET_FAVORITE_ONLY";
export const SET_DAG_MODE = "SET_DAG_MODE";
