export default (theme) => ({
  container: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    marginTop: 4,
    marginRight: 8,
    cursor: "pointer",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 600,
  },
});
