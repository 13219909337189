import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import {
  searchInFullApp,
  setSearchCount,
  setSearchLoading,
  setSearchOpen,
  setSearchText,
  setSelectedCategories,
  setSelectedDegreeLevels,
  setSelectedDegreeRequirements,
} from "actions";
import CareerBubble from "hooks/CareerBubble";
import hotkeys from "hotkeys-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function QuickSearch() {
  const [anchorEl, setAnchorEl] = React.useState();
  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const categories = useSelector((state) => state.cpfmCategories);
  const degreeRequirements = useSelector((state) => state.degreeRequirements);
  const degreeLevels = useSelector((state) => state.degreeLevels);
  const searchOpen = useSelector((state) => state.app.searchOpen);
  const searchResults = useSelector((state) => state.searchResults);

  const searchText = useSelector((state) => state.search.searchText);
  const loading = useSelector((state) => state.search.searchLoading);
  const searchCount = useSelector((state) => state.search.searchCount);
  const selectedCategories = useSelector(
    (state) => state.search.selectedCategories
  );
  const selectedDegreeLevels = useSelector(
    (state) => state.search.selectedDegreeLevels
  );
  const selectedDegreeRequirements = useSelector(
    (state) => state.search.selectedDegreeRequirements
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (searchCount > 0) {
      dispatch(setSearchLoading(true));
      const filters = [];
      if (searchText) {
        filters.push({ name: "title", comparison: "like", value: searchText });
      }
      if (selectedCategories.length > 0) {
        filters.push({
          name: "categories",
          comparison: "in",
          value: selectedCategories
            .reduce((a, v) => {
              a.push(v.id);
              return a;
            }, [])
            .join(","),
        });
      }
      if (selectedDegreeLevels.length > 0) {
        filters.push({
          name: "degreeLevels",
          comparison: "in",
          value: selectedDegreeLevels
            .reduce((a, v) => {
              a.push(v.id);
              return a;
            }, [])
            .join(","),
        });
      }
      if (selectedDegreeRequirements.length > 0) {
        filters.push({
          name: "degreeRequirements",
          comparison: "in",
          value: selectedDegreeRequirements
            .reduce((a, v) => {
              a.push(v.id);
              return a;
            }, [])
            .join(","),
        });
      }
      dispatch(searchInFullApp(filters)).then(() =>
        dispatch(setSearchLoading(false))
      );
    }
  }, [
    searchCount,
    selectedCategories,
    selectedDegreeLevels,
    selectedDegreeRequirements,
  ]);

  React.useEffect(() => {
    hotkeys("ctrl+k, command+k", function () {
      dispatch(setSearchOpen(true));
    });
  }, []);

  const handleClose = () => {
    dispatch(setSearchOpen(false));
  };
  const handleOpen = () => {
    dispatch(setSearchOpen(true));
  };

  const classes = useStyles(styles);

  function handleSearch(e) {
    const t = e.target.value;
    dispatch(setSearchText(t));
  }

  const handleCategoryClicked = (_c) => () => {
    const index = selectedCategories.findIndex((c) => c.id === _c.id);
    if (index >= 0) {
      const _selectedCategories = [...selectedCategories];
      _selectedCategories.splice(index, 1);
      dispatch(setSelectedCategories(_selectedCategories));
    } else {
      dispatch(setSelectedCategories([...selectedCategories, _c]));
    }
  };

  const handleDegreeLevelClicked = (_c) => () => {
    const index = selectedDegreeLevels.findIndex((c) => c.id === _c.id);
    if (index >= 0) {
      const _selectedDegreeLevels = [...selectedDegreeLevels];
      _selectedDegreeLevels.splice(index, 1);
      dispatch(setSelectedDegreeLevels(_selectedDegreeLevels));
    } else {
      dispatch(setSelectedDegreeLevels([...selectedDegreeLevels, _c]));
    }
  };

  const handleDegreeRequirementClicked = (_c) => () => {
    const index = selectedDegreeRequirements.findIndex((c) => c.id === _c.id);
    if (index >= 0) {
      const _selectedDegreeRequirements = [...selectedDegreeRequirements];
      _selectedDegreeRequirements.splice(index, 1);
      dispatch(setSelectedDegreeRequirements(_selectedDegreeRequirements));
    } else {
      dispatch(
        setSelectedDegreeRequirements([...selectedDegreeRequirements, _c])
      );
    }
  };

  function getList(array, title, labelKey, callback, checked) {
    const JSX = [<Typography variant="overline">{title}</Typography>];
    for (const key in array) {
      if (Object.hasOwnProperty.call(array, key)) {
        const element = array[key];
        JSX.push(
          <List dense>
            <ListItem button style={{ padding: 0 }} onClick={callback(element)}>
              <ListItemAvatar>
                <Checkbox checked={checked(element)} size="small" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body2">{element[labelKey]}</Typography>
                }
              />
            </ListItem>
          </List>
        );
      }
    }

    return JSX;
  }

  const getFilters = () => {
    return (
      <Grid
        item
        xs={mobile ? 12 : 3}
        style={{
          position: "sticky",
          top: 0,
          overflow: "scroll",
          height: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "cooper-black-std, serif",
              }}
              variant="h6"
            >
              Filters
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            {getList(
              categories,
              "Categories",
              "title",
              handleCategoryClicked,
              (element) => selectedCategories.indexOf(element) >= 0
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            {getList(
              degreeLevels,
              "Degree Levels",
              "value",
              handleDegreeLevelClicked,
              (element) => selectedDegreeLevels.indexOf(element) >= 0
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            {getList(
              degreeRequirements,
              "Degree Requirements",
              "value",
              handleDegreeRequirementClicked,
              (element) => selectedDegreeRequirements.indexOf(element) >= 0
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.container} onClick={handleOpen}>
        <SearchIcon />
        <InputBase
          value={searchText}
          onChange={handleSearch}
          placeholder="Search Careers"
          disabled
          fullWidth
          className={classes.input}
          inputProps={{ "aria-label": "search cpfm" }}
        />
        <Hidden smDown>
          <div
            style={{
              fontSize: 12,
              width: 28,
              opacity: 0.8,
              border: "solid 1px grey",
              padding: "2px 4px",
              borderRadius: 4,
            }}
          >
            <i class="fa-solid fa-command"></i>K
          </div>
        </Hidden>
      </div>
      <Dialog
        open={searchOpen}
        onClose={handleClose}
        fullScreen
        scroll="paper"
        classes={{
          paper: mobile ? classes.paperSm : classes.paper,
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Hidden smDown>
            <div
              onClick={() => dispatch(setSearchOpen(false))}
              style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}
            >
              <IconButton>
                <Close />
              </IconButton>
            </div>
          </Hidden>
          <Paper
            style={{
              padding: 16,
              maxWidth: 600,
              margin: "auto",
              display: "flex ",
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <SearchIcon style={{ marginRight: 18 }} />
            <Hidden mdUp>
              <Tooltip title="filters">
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  style={{ marginRight: 18 }}
                  size="small"
                >
                  <Icon
                    class="fa-solid fa-sliders-simple"
                    style={{ color: "black" }}
                  />
                </IconButton>
              </Tooltip>
              <Popover open={Boolean(anchorEl)} onClose={() => setAnchorEl()}>
                <div style={{ padding: 8, maxHeight: 400, overflow: "scroll" }}>
                  {getFilters()}
                </div>
              </Popover>
            </Hidden>
            <InputBase
              value={searchText}
              onChange={handleSearch}
              placeholder="Search Careers"
              autoFocus
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(setSearchCount(searchCount + 1));
                  // put the login here
                }
              }}
              style={{ flexGrow: 1 }}
              inputProps={{ "aria-label": "search cpfm" }}
            />
            <Hidden smDown>
              <Button
                onClick={() => dispatch(setSearchCount(searchCount + 1))}
                style={{ background: "#ff9800", color: "white" }}
              >
                Search
              </Button>
            </Hidden>
          </Paper>
        </DialogTitle>
        <DialogContent
          style={{
            flex: 1,
            height: 0,
            position: "relative",
          }}
        >
          <Grid
            container
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <Hidden smDown>{getFilters()}</Hidden>
            <Grid
              item
              xs={mobile ? 12 : 9}
              style={{ padding: 16, overflow: "scroll", height: "100%" }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    style={{
                      fontFamily: "cooper-black-std, serif",
                    }}
                  >
                    Search Results
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {searchResults.length} result
                    {searchResults.length > 1 ? "s" : ""} for
                  </Typography>
                </Grid>
                {searchText ? (
                  <Grid item>
                    <Chip
                      size="small"
                      label={searchText}
                      onDelete={() => dispatch(setSearchText(""))}
                    />
                  </Grid>
                ) : (
                  []
                )}
                {selectedCategories.map((c) => (
                  <Chip
                    size="small"
                    label={c.title}
                    onDelete={handleCategoryClicked(c)}
                  />
                ))}
                {selectedDegreeLevels.map((c) => (
                  <Chip
                    size="small"
                    label={c.value}
                    onDelete={handleDegreeLevelClicked(c)}
                  />
                ))}
                {selectedDegreeRequirements.map((c) => (
                  <Chip
                    size="small"
                    label={c.value}
                    onDelete={handleDegreeRequirementClicked(c)}
                  />
                ))}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: 16,
                    }}
                  >
                    <CircularProgress size={20} />
                    <Typography style={{ marginLeft: 6 }}>
                      Fetching results...
                    </Typography>
                  </div>
                ) : (
                  <div style={{ paddingTop: 16 }}>
                    {searchResults.map((c) => (
                      <Grid item xs={12}>
                        <CareerBubble variant="inline" career={c} />
                      </Grid>
                    ))}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Hidden mdUp>
          <DialogActions>
            <Button
              onClick={() => dispatch(setSearchOpen(false))}
              fullWidth
              variant="contained"
            >
              Close
            </Button>
            <Button
              onClick={() => setSearchCount((i) => i + 1)}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Search
            </Button>
          </DialogActions>
        </Hidden>
      </Dialog>
    </>
  );
}
