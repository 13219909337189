// import PropTypes from 'prop-types';
// material-ui
import {
  Collapse,
  fade,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CareerBubble from "hooks/CareerBubble";
import React from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function SubCategoryTree({ subCategory, category, color }) {
  const [careers, setCareers] = React.useState();
  const [expanded, setExpanded] = React.useState(true);
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const fullApp = useSelector((state) => state.fullApp);

  React.useEffect(() => {
    const _c = fullApp?.reduce((a, v) => {
      if (subCategory.careerIDs?.find((id) => id === v.id)) {
        a.push(v);
      }
      return a;
    }, []);
    setCareers(_c);
  }, [fullApp]);

  function handleExpand() {
    setExpanded((p) => !p);
  }

  return (
    <div className={classes.root} style={{ borderColor: fade(color, 0.3) }}>
      <div>
        <Typography
          variant="h6"
          onClick={handleExpand}
          classes={{
            h6: classes.kimi,
          }}
          style={{ lineHeight: 1, cursor: "pointer" }}
        >
          {subCategory.title}{" "}
          <IconButton size="small">
            <Icon
              style={{
                transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform .2s",
              }}
              fontSize="inherit"
              className="fad fa-chevron-down"
            />
          </IconButton>
        </Typography>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container>
          {careers?.map((c) => (
            <CareerBubble key={c.id} career={c} color={color} />
          ))}
        </Grid>
      </Collapse>
    </div>
  );
}
