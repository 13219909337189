export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    margin: 16,
    height: "calc(100% - 40px)",
    borderRadius: 4,
    padding: 0,
  },
  paperSm: {
    height: "100%",
    padding: 0,
  },
});
