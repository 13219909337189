import { GET_CPFM_CATEGORIES } from "constants/cpfmCategories";

export default (state = null, action) => {
  switch (action.type) {
    case `${GET_CPFM_CATEGORIES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
