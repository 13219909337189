export default (theme) => ({
  container: {
    padding: 5,
  },
  tag: {
    padding: 2,
    margin: 6,
    border: "solid 1px #b0bec5",
    background: "#b0bec5",
    borderRadius: 110,
    height: 110,
    width: 110,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  },

  tagMobile: {
    padding: 8,
    margin: 4,
    border: "solid 1px #b0bec5",
    background: "#b0bec5",
    borderRadius: 70,
    height: 70,
    width: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  },
});
