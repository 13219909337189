import {
  SET_SEARCH_COUNT,
  SET_SEARCH_LOADING,
  SET_SEARCH_TEXT,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_DEGREE_LEVELS,
  SET_SELECTED_DEGREE_REQUIREMENTS,
} from "constants/search";

export default (
  state = {
    searchText: "",
    searchLoading: false,
    searchCount: 0,
    selectedCategories: [],
    selectedDegreeLevels: [],
    selectedDegreeRequirements: [],
  },
  action
) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.searchText };
    case SET_SEARCH_LOADING:
      return { ...state, searchLoading: action.searchLoading };
    case SET_SEARCH_COUNT:
      return { ...state, searchCount: action.searchCount };
    case SET_SELECTED_CATEGORIES:
      return { ...state, selectedCategories: action.selectedCategories };
    case SET_SELECTED_DEGREE_LEVELS:
      return { ...state, selectedDegreeLevels: action.selectedDegreeLevels };
    case SET_SELECTED_DEGREE_REQUIREMENTS:
      return {
        ...state,
        selectedDegreeRequirements: action.selectedDegreeRequirements,
      };
    default:
      return state;
  }
};
