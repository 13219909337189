import { GET_SKILL_TAGS } from "constants/skillTags";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SKILL_TAGS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
