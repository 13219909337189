import {
  DECREMENT_PLAY_CAREER_INDEX,
  INCREMENT_PLAY_CAREER_INDEX,
  OPEN_SELECTED_CAREER_ON_GRAPH,
  SET_CATEGORIES_ENABLED,
  SET_DAG_MODE,
  SET_FAVORITE_ONLY,
  SET_GRAPH_OPEN,
  SET_GUIDED_TOUR_PLAYING,
  SET_ORBITING,
  SET_PLAY_CAREER_INDEX,
  SET_SELECTED_CAREER_ON_GRAPH,
  SET_SELECTED_QUIZ_ON_GRAPH,
  SET_SHOW_UNSUGGESTED_CAREERS,
  TOGGLE_GUIDED_TOUR_PLAYING,
} from "constants/graph";

export default (
  state = {
    categoriesEnabled: {},
    selectedCareerOnGraph: null,
    orbiting: false,
    showUnsuggestedCareers: true,
    selectedQuizOnGraph: null,
    guidedTourPlaying: false,
    playCareerIndex: 0,
    graphOpen: false,
    favoriteOnly: false,
    dagMode: "td",
  },
  action
) => {
  var isNotNull;
  var nextIndex;
  switch (action.type) {
    case SET_DAG_MODE:
      return {
        ...state,
        dagMode: action.dagMode,
      };
    case SET_FAVORITE_ONLY:
      return {
        ...state,
        favoriteOnly: action.favoriteOnly,
      };
    case SET_GRAPH_OPEN:
      return {
        ...state,
        graphOpen: action.graphOpen,
      };
    case OPEN_SELECTED_CAREER_ON_GRAPH:
      return {
        ...state,
        guidedTourPlaying: false,
      };
    case INCREMENT_PLAY_CAREER_INDEX:
      isNotNull = !isNaN(state.playCareerIndex);
      nextIndex = 0;

      if (isNotNull) nextIndex = state.playCareerIndex + 1;
      return {
        ...state,
        playCareerIndex: nextIndex,
      };
    case DECREMENT_PLAY_CAREER_INDEX:
      isNotNull = !isNaN(state.playCareerIndex);
      nextIndex = 0;

      if (isNotNull && state.playCareerIndex > 0)
        nextIndex = state.playCareerIndex - 1;
      return {
        ...state,
        playCareerIndex: nextIndex,
      };
    case SET_PLAY_CAREER_INDEX:
      return { ...state, playCareerIndex: action.playCareerIndex };
    case SET_CATEGORIES_ENABLED:
      return { ...state, categoriesEnabled: action.categoriesEnabled };
    case SET_SELECTED_CAREER_ON_GRAPH:
      return { ...state, selectedCareerOnGraph: action.selectedCareerOnGraph };
    case SET_ORBITING:
      return { ...state, orbiting: action.orbiting };
    case SET_SHOW_UNSUGGESTED_CAREERS:
      return {
        ...state,
        showUnsuggestedCareers: action.showUnsuggestedCareers,
      };
    case SET_SELECTED_QUIZ_ON_GRAPH:
      return {
        ...state,
        selectedQuizOnGraph: action.selectedQuizOnGraph,
      };
    case SET_GUIDED_TOUR_PLAYING:
      return {
        ...state,
        guidedTourPlaying: action.guidedTourPlaying,
      };
    case TOGGLE_GUIDED_TOUR_PLAYING:
      return {
        ...state,
        guidedTourPlaying: !state.guidedTourPlaying,
      };
    default:
      return state;
  }
};
