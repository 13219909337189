import { appMiddleware } from "appMiddleware";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { urlMiddleware } from "urlMiddleware";
import rootReducer from "./reducers/rootReducer";

const persistedState = localStorage.getItem("cpfmState")
  ? JSON.parse(localStorage.getItem("cpfmState"))
  : {};

export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk, createLogger(), appMiddleware, urlMiddleware)
);
