// import PropTypes from 'prop-types';
// material-ui
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import FeatureCareerCard from "hooks/FeatureCareerCard";
import React, { useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export default function FeaturedCareerGallery({
  random = false,
  count = 8,
  careers,
}) {
  const cpfmCareers = useSelector((state) => state.fullApp);
  const [featuredCareers, setFeaturedareers] = React.useState([]);
  const ref = useRef();

  React.useEffect(() => {
    if (cpfmCareers?.length && random) {
      const _random = getRandom(cpfmCareers, count);
      setFeaturedareers(_random);
    } else if (careers) {
      setFeaturedareers(careers);
    }
  }, [cpfmCareers, random, careers]);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();

  let cardWidth = 0;
  if (xs) {
    cardWidth = 280;
  }
  if (md) {
    cardWidth = 320;
  }
  if (lg) {
    cardWidth = 420;
  }

  const handleCareerClick = (c) => () => {
    dispatch(Actions.setSelectedCareer(c));
  };

  return (
    <ScrollContainer
      hideScrollbars={false}
      ref={ref}
      style={{
        flex: 1,
        width: 0,
        paddingBottom: 30,
        scrollBehavior: "smooth",
      }}
      onMouseMove={(e) => {
        console.log(e);
      }}
    >
      <Grid
        container
        style={{
          width: featuredCareers.length * cardWidth,
        }}
        alignItems="stretch"
      >
        {featuredCareers.map((c, i) => {
          return (
            <Grid item>
              <FeatureCareerCard
                career={c}
                cardWidth={cardWidth}
                onClick={handleCareerClick}
              />
            </Grid>
          );
        })}
      </Grid>
    </ScrollContainer>
  );
}
