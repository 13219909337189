import { SET_CAREER_PREFERENCES } from "constants/app";
import { GET_STUDENT_CAREERS } from "constants/studentCareers";

export default (state = {}, action) => {
  switch (action.type) {
    case `${GET_STUDENT_CAREERS}_SUCCESS`:
      const payload = action.payload;
      const _s1 = {};
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const careerPreference = payload[key];
          _s1[careerPreference.careerID] = { love: careerPreference.favorite };
        }
      }
      return _s1;
    case SET_CAREER_PREFERENCES:
      const _s2 = { ...state };
      const preference = action.value;
      _s2[preference.id] = action.value;
      return _s2;
    default:
      return state;
  }
};
